import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { labelStyle } from './Label';

const MarkdownWrapper = styled.div`
  h2 {
    ${labelStyle}
    margin-top: 100px;

    ${({ theme }) => theme.media.tablet`
      margin-top: 80px;
    `}
  }

  h3 {
    font-size: ${({ theme }) => theme.font.size.heading.secondary};
  }

  p {
    margin-bottom: 2rem;
  
    img {
      display: block;
    }

    ${({ theme }) => theme.media.tablet`
      max-width: none;
    `}
  }

  ul,
  ol {
    margin: 1rem 0 2rem;
  }

  li {
    margin-bottom: 0.5em;
  }

  h3 + p,
  ol,
  ul {
    max-width: 560px;
  }
`;

export const HTMLContent = ({ content }) => (
  <MarkdownWrapper dangerouslySetInnerHTML={{ __html: content }} />
);

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
);

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
