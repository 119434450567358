import styled from 'styled-components';

export const ColumnContainer = styled.div`
  display: flex;
  margin: -30px;

  ${({ theme }) => theme.media.tablet`
    flex-wrap: wrap;
    margin: -20px;
  `} ${({ theme }) => theme.media.mobile`
    margin: -20px 0;
  `};
`;

export const Column = styled.div`
  flex: 1;
  padding: 30px;

  ${({ theme }) => theme.media.tablet`
    flex: initial;
    flex-basis: 100%;
    padding: 20px;
  `} ${({ theme }) => theme.media.mobile`
    padding: 20px 0;
  `};

  p:first-child {
    margin-top: 0;
  }
`;

export const ColumnTitle = styled.h3`
  margin-top: 0;
`;

export const ColumnText = styled.p`
  &:last-child {
    margin-bottom: 0;

    ${({ theme }) => theme.media.tablet`
      margin-bottom: 1rem;
    `};
  }
`;