import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { Location } from '@reach/router';
import { Twitter, Facebook } from 'react-social-sharing';
import Layout from '../containers/Layout';
import Content, { HTMLContent } from '../components/Content';
import Label from '../components/Label';
import Hero from '../containers/Hero';
import Title from '../components/Title';
import Button from '../components/Button';
import Caption from '../components/Caption';
import Section from '../components/Section';
import MissionStatement from '../components/MissionStatement';
import { Column, ColumnContainer, ColumnTitle, ColumnText } from '../components/Columns';

const SectionTitle = Label.withComponent('h2');

const ButtonLink = Button.withComponent(Link);

const SectionTitleCenter = styled(SectionTitle)`
  margin-bottom: 3rem;
  text-align: center;
`;

const Box = styled.div`
  text-align: ${props => props.alignContent};
  margin-top: 4rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.tablet`
    margin-bottom: 3rem;
    display: block;
  `}
`;

const Social = styled.div`
  margin: 0 -0.5em;
  font-size: 12px;
`;

export const CaseStudyTemplate = ({
  content,
  contentComponent,
  challenge,
  task,
  link,
  outcome,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  let tagString = tags && tags.length ? tags.map(tag => `${tag}`).join(', ') : null;

  return (
    <Fragment>
      {helmet || ''}
      <Hero>
        <Label>Case study</Label>
        <Title>{title}</Title>
        {tagString && <Caption>{tagString}</Caption>}
      </Hero>
      <Section separator>
        <SectionTitleCenter>Task</SectionTitleCenter>
        <MissionStatement>{task}</MissionStatement>
        <Box alignContent="center">
          <Button target="_blank" href={link}>
            See finished product
          </Button>
        </Box>
      </Section>
      <Section separator>
        <ColumnContainer>
          <Column>
            <ColumnTitle>Challenge</ColumnTitle>
            <ColumnText>{challenge}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Outcome</ColumnTitle>
            <ColumnText>{outcome}</ColumnText>
          </Column>
        </ColumnContainer>
      </Section>
      <Section separator>
        <PostContent content={content} />
      </Section>
      <Section last>
        <Row>
          <h2>Thank you for watching!</h2>
          <ButtonLink to="/projects">Browse more projects</ButtonLink>
        </Row>
        <Label>Share this case</Label>
        <Social>
          <Location>
            {({ location }) => (
              <>
                <Facebook link={location.href} />
                <Twitter link={location.href} />
              </>
            )}
          </Location>
        </Social>
      </Section>
    </Fragment>
  );
};

CaseStudyTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
};

const CaseStudy = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <CaseStudyTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet>
            <title>{post.frontmatter.title}</title>
            <meta property="description" content={post.frontmatter.task} />
            <meta property="og:title" content={post.frontmatter.title} />
            <meta property="og:description" content={post.frontmatter.task} />
            <meta property="og:image" content={post.frontmatter.thumbnail} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        task={post.frontmatter.task}
        link={post.frontmatter.link}
        challenge={post.frontmatter.challenge}
        outcome={post.frontmatter.outcome}
      />
    </Layout>
  );
};

CaseStudy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CaseStudy;

export const pageQuery = graphql`
  query CaseStudyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        task
        link
        challenge
        outcome
        tags
        thumbnail
      }
    }
  }
`;
